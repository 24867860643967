/* eslint-disable no-console */
import cn from 'classnames';
import React, { useState, useEffect } from 'react';
import styles from './index.module.scss';
import { IBlueRibbonBase } from './store';
import { toCamelCase } from '~/common/utils';
import { Button } from '@ui-elements';
import { useIsMobile } from '~/common/hooks/use-is-mobile';

export const BlueRibbonSection = React.memo<IBlueRibbonBase>(
  ({
    id,
    isBody,
    contentCta,
    title,
    subTitle,
    fontColor,
    backgroundColor,
    subTitleMobile,
  }) => {
    const [isWindowMobile, setWindowMobile] = useState(false);
    const [isContentLoaded, setContentLoaded] = useState(false);
    const { isMobile } = useIsMobile();
    const [visible, setVisible] = useState(true);
    const firstCta = contentCta.length > 0 ? contentCta[0] : null;
    const hasContent = !!title || contentCta.length > 0;
    const isExtended = !isBody && hasContent && (subTitle?.length ?? 0) > 350;

    const removeElement = (e) => {
      e.preventDefault();
      setVisible((prev) => !prev);
      document.getElementsByTagName('body')[0].classList.remove('has-promoBar');
    };
    const renderCTA = () =>
      contentCta?.map((cta, idx) => {
        const buttonProps: any = {
          type: toCamelCase(cta.ctaButtonType),
        };
        if (cta.ctaColor) {
          buttonProps.style = {
            borderColor: `#${cta.ctaColor}`,
            backgroundColor:
              cta.ctaButtonType !== 'bordered' &&
              cta.ctaButtonType !== 'borderedSecondary'
                ? `#${cta.ctaColor}`
                : 'initial',
            color:
              cta.ctaButtonType === 'bordered' ||
              cta.ctaButtonType === 'borderedSecondary'
                ? `#${cta.ctaColor}`
                : `#${backgroundColor}`,
          };
        }
        return (
          <Button key={idx} className={styles.promoBar__btn} {...buttonProps}>
            {cta.ctaUrl?.name || ''}
          </Button>
        );
      });

    useEffect(() => {
      const handleInitialLoad = () => {
        setWindowMobile(isMobile());
        setContentLoaded(true);
      };
      const handleResize = () => {
        setWindowMobile(isMobile());
      };
      window.addEventListener('load', handleInitialLoad);
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('load', handleInitialLoad);
      };
    }, [isMobile]);

    if (visible && isContentLoaded) {
      return (
        <div
          key={id}
          className={cn(
            isBody ? 'full-device-width' : `${styles['promoBar--fixed']}`,
            `${styles.promoBar} ${visible ? styles['promoBar--onNow'] : ''}`
          )}
          style={{
            ...(backgroundColor ? { background: `#${backgroundColor}` } : {}),
          }}
        >
          <div className={styles.promoBar__content}>
            <div className={styles['container container--large']}>
              <a
                href={firstCta?.ctaUrl?.url}
                className={cn(
                  styles.promoBar__link,
                  {
                    [styles.promoBar__link_isBody]: isBody,
                  },
                  { [styles.promoBar__link_addPadding]: isExtended }
                )}
              >
                <div className={styles.promoBar__content}>
                  {title ? (
                    <h3
                      className={styles.promoBar__title}
                      style={fontColor ? { color: `#${fontColor}` } : {}}
                    >
                      {title}
                    </h3>
                  ) : (
                    <></>
                  )}
                  <div
                    className={cn(styles.promoBar__terms, {
                      [styles.promoBar__terms_hasContent]: hasContent,
                    })}
                    style={fontColor ? { color: `#${fontColor}` } : {}}
                    dangerouslySetInnerHTML={{
                      __html: isWindowMobile
                        ? (subTitleMobile as string)
                        : (subTitle as string),
                    }}
                  />
                  {renderCTA()}
                </div>
              </a>
            </div>
          </div>
          {!isBody ? (
            <div className="promoBar__close" onClick={removeElement} />
          ) : (
            <></>
          )}
        </div>
      );
    }
    return <></>;
  }
);
